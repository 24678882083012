import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { format, startOfWeek } from 'date-fns'
import { nextSunday } from 'date-fns/esm';

function App() {
  const [color, setColor] = useState('rainbow');
  const [caption, setCaption] = useState('Medienzeit');
  const [name, setName] = useState('Kind');
  const [cells, setCells] = useState(4);
  const [rows, setRows] = useState(8);
  const [minute, setMinute] = useState(15);

  let obj: any = { 'title': 'Medienzeit', 'line1': '00:30 Stunden', 'line2': 'für ' };
  let row: any[] = [];

  for (let i = 0; i < cells; i++) {
    row.push(obj);
  }

  let data: any[] = [];
  for (let i = 0; i < rows; i++) {
    data.push(row);
  }

  useEffect(() => {
    // Update the document title using the browser API
    if (color === '') {
      setColor('rainbow');
    }
  }, [color]);

  const from_date = startOfWeek(new Date(), { weekStartsOn: 1 });
  const to_date = nextSunday(from_date);
  const period = `${format(from_date, 'dd.MM.')}-${format(to_date, 'dd.MM.yy')}`;

  const className = color === 'rainbow' ? 'rainbow-box' : '';
  const style = color !== 'rainbow' ? { 'borderColor': `#${color}`, 'border': '5px solid', 'padding': '1em' } : {};
  return (
    <React.Fragment>
      <div className="parameters">
        <div className="row">
        <div className="col-lg-3 col-xs-12">
            <InputGroup className="mb-6">
              <InputGroup.Text id="basic-addon1">
                Bezeichnung
              </InputGroup.Text>
              <FormControl id="basic-url1" aria-describedby="basic-addon1" value={caption} onChange={(e: any) => setCaption(e.target.value)} className="inputfield" />
            </InputGroup>
          </div>
          <div className="col-lg-3 col-xs-12">
            <InputGroup className="mb-6">
              <InputGroup.Text id="basic-addon1">
                Farbe (rainbow oder HEX)
              </InputGroup.Text>
              <FormControl id="basic-url1" aria-describedby="basic-addon1" value={color} onChange={(e: any) => setColor(e.target.value)} className="inputfield" />
            </InputGroup>
          </div>
          <div className="col-lg-2 col-xs-12">
            <InputGroup className="mb-6">
              <InputGroup.Text id="basic-addon2">
                Spalten (Default: 4)
              </InputGroup.Text>
              <FormControl id="basic-url2" aria-describedby="basic-addon2" value={cells} onChange={(e: any) => setCells(e.target.value)} className="inputfield" />
            </InputGroup>
          </div>
          <div className="col-lg-2 col-xs-12">
            <InputGroup className="mb-6">
              <InputGroup.Text id="basic-addon3">
                Zeilen (Default: 8)
              </InputGroup.Text>
              <FormControl id="basic-url3" aria-describedby="basic-addon3" value={rows} onChange={(e: any) => setRows(e.target.value)} className="inputfield" />
            </InputGroup>
          </div>
          <div className="col-lg-4 col-xs-12">
            <InputGroup className="mb-6">
              <InputGroup.Text id="basic-addon4">
                Name
              </InputGroup.Text>
              <FormControl id="basic-url4" aria-describedby="basic-addon4" value={name} onChange={(e: any) => setName(e.target.value)} className="inputfield" />
            </InputGroup>
          </div>
          <div className="col-lg-4 col-xs-12">
            <InputGroup className="mb-6">
              <InputGroup.Text id="basic-addon4">
                Minuten
              </InputGroup.Text>
              <FormControl id="basic-url4" aria-describedby="basic-addon4" value={minute} onChange={(e: any) => setMinute(e.target.value)} className="inputfield" />
            </InputGroup>
          </div>
          <div className="col-lg-1 col-xs-12">
            <Button className="col-lg-12 btn btn-block col-xs-12" onClick={() => setColor((Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'))}>Zufall</Button>
          </div>
        </div>
      </div>
      <div className="example">
        <div className="row">
          <div className="col-6">
            <div className={className} style={style}>
              <div className="title">{obj.title}</div>
              <div className="subtitle">{obj.line1}</div>
              <div className="subtitle">{obj.line2}{name}</div>
              <div className="small-footer">{period}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="app">
        {data.map(
          (r: any[], i: number) => {
            var m = minute % 60;
            var h = (minute-m)/60;
            var HHMM = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
          return (<div key={`rows-${i}`} className="row">
            {r.map((c: any, j: number) => (
              <div key={`rows-${i}-cells-${j}`} className="col-3">
                <div className={className} style={style}>
                  <div className="title">{caption}</div>
                  <div className="subtitle">{HHMM} Stunden</div>
                  <div className="subtitle">{c.line2}{name}</div>
                  <div className="small-footer">{period}</div>
                </div>
              </div>
            ))}
          </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default App;
